.EditHotel-Container {
    padding: 0px 140px 0px 140px;
    margin-top: 37px;
  }
  
  .EditHotel-HeadContainer {
    display: flex;
    margin-bottom: 26px;
  }
  
  .EditHotel-HeadTitle {
    font-family: "Cairo", sans-serif !important;
    font-size: 25px !important;
    color: #393737;
    font-weight: 700 !important;
  }
  
  .EditHotel-Content {
    display: flex;
    flex-direction: column;
    padding: 19px 24px 32px 16px;
    border-radius: 0px 6px 6px 6px;
    border: solid 3px #3f7cdf;
    background-color: #fff;
    min-height: 40vh;
    position: relative;
  }
  
  .EditHotel-AccordionHeadTitle {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: #393737 !important;
    font-weight: 600 !important;
  }
  
  .EditHotel-FormFooter {
    display: flex;
    margin-top: 5px;
    flex-direction: row-reverse;
  }
  
  .EditHotel-NewButton {
    font-family: "Cairo", sans-serif !important;
    font-size: 15px !important;
    color: #fff !important;
    font-weight: bold !important;
    background-color: #3f7cdf !important;
    padding: 12px 0px !important;
    border-radius: 6px !important;
    border: solid 2px #eee !important;
    width: 25% !important;
  }
  
  .EditHotel-BackLink {
    padding: 12px 0px !important;
    border-radius: 6px !important;
    border: solid 2px #6c757d !important;
    width: 25% !important;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px !important;
  }
  
  .EditHotel-BackLinkTitle {
    font-family: "Cairo", sans-serif !important;
    font-size: 15px !important;
    color: #6c757d !important;
    font-weight: bold !important;
  }
  
  .EditHotel-FormRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .EditHotel-FormRow:last-of-type {
    margin-bottom: 0px;
  }
  
  .EditHotel-SelectInput div .MuiSelect-icon {
    color: #3f7cdf !important;
  }
  
  .EditHotel-SelectInput {
    width: 25%;
    margin-right: 15px !important;
  }
  
  .EditHotel-SelectInput:last-of-type {
    width: 25%;
    margin-right: 0px !important;
  }
  
  .EditHotel-FormInputError .MuiFormHelperText-root.Mui-error {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: #cf2528 !important;
    font-weight: 600 !important;
  }
  
  .EditHotel-FormInputError div .MuiOutlinedInput-notchedOutline {
    border: solid 2px #cf2528 !important;
  }
  
  .EditHotel-FormInputError .MuiFormHelperText-root {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: #cf2528 !important;
    font-weight: 600 !important;
  }
  
  .EditHotel-Input .MuiFormLabel-root {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: rgba(57, 55, 55, 0.4) !important;
    font-weight: 600 !important;
  }
  
  .EditHotel-Input div .MuiOutlinedInput-notchedOutline {
    border-radius: 6px !important;
    border: solid 2px #eee;
  }
  
  .EditHotel-Input .MuiFormLabel-root.Mui-focused {
    color: #3f7cdf !important;
  }
  
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #3f7cdf !important;
  }
  
  .EditHotel-Input div .MuiInputBase-input {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: rgba(57, 55, 55, 0.4) !important;
    font-weight: 600 !important;
    height: 22px;
  }
  
  .EditHotel-Input div .MuiInputBase-input:focus {
    color: #393737 !important;
  }
  
  .EditHotel-InputRequired {
    color: #cf2528 !important;
    font-family: "Cairo", sans-serif !important;
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  
  .EditHotel-FormDetailsContainer {
    display: flex !important;
    flex-direction: column !important;
  }
  
  .EditHotel-BuildingNumberInput {
    width: 25%;
    margin-right: 15px !important;
  }
  
  .EditHotel-StreetInput {
    width: 50%;
    margin-right: 15px !important;
  }
  
  .EditHotel-DistrictInput {
    width: 25%;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  
  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  
  .EditHotel-FacilityPoboxInput {
    width: 30%;
    margin-right: 15px !important;
  }
  
  .EditHotel-FacilityPhoneInput {
    width: 35%;
    margin-right: 15px !important;
  }
  
  .EditHotel-FacilityEmailInput {
    width: 35%;
  }
  
  .EditHotel-FacilityClassificationInput {
    width: 30% !important;
  }
  
  .EditHotel-FacilityClassificationStartDateInput {
    width: 35%;
    margin-right: 15px !important;
  }
  
  .EditHotel-FacilityClassificationEndDateInput {
    width: 35%;
  }
  
  .EditHotel-BoxTabs {
    display: flex;
    flex-direction: column;
  }
  
  .EditHotel-BoxTabs-Tab.Mui-selected {
    color: #fff !important;
    background-color: #3f7cdf !important;
    border-radius: 6px 6px 0px 0px !important;
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    font-weight: bold !important;
    border-bottom: unset !important;
    padding: 12px 16px !important;
  }
  
  .EditHotel-BoxTabs-Tab.Mui-selected:first-of-type {
    margin-right: unset !important;
  }
  
  div[lang="en"] .EditHotel-BoxTabs-Tab.Mui-selected:first-of-type {
    margin-left: unset !important;
  }
  
  .EditHotel-BoxTabs-Tab:first-of-type {
    margin-right: unset !important;
  }
  
  div[lang="en"] .EditHotel-BoxTabs-Tab:first-of-type {
    margin-left: unset !important;
  }

  .EditHotel-BoxTabs .MuiTabs-scroller .MuiTabs-indicator {
    display: none !important;
  }
  
  .EditHotel-BoxTabs-Tab {
    color: #3f7cdf !important;
    border: 3px solid #3f7cdf !important;
    border-radius: 6px 6px 0px 0px !important;
    border-bottom: unset !important;
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    font-weight: bold !important;
    margin-right: 10px !important;
    padding: 12px 16px !important;
  }
  
  div[lang="en"] .EditHotel-BoxTabs-Tab {
    margin-right: unset !important;
    margin-left: 10px !important;
  }
  
  .EditHotel-BoxTabs div .css-19kzrtu {
    border: 3px solid #3f7cdf;
  }
  
  .EditHotel-TabPanel-Container {
    border: 3px solid #3f7cdf;
  }
  
  .PrivateTabIndicator-colorSecondary-9 {
    display: none !important;
  }
  
  .EditHotel-FacilityNameENInput {
    width: 34%;
    margin-right: 15px !important;
  }
  
  .EditHotel-PlaceNameENInput {
    width: 33%;
    margin-right: 15px !important;
  }
  
  .EditHotel-LocationENInput {
    width: 33%;
  }
  
  .EditHotel-DescriptionENInput {
    width: 100%;
  }
  
  .EditHotel-DescriptionENInput div .MuiInputBase-input {
    height: unset !important;
  }
  
  .EditHotel-FacilityNameARInput {
    width: 34%;
    margin-right: 15px !important;
  }
  
  .EditHotel-PlaceNameARInput {
    width: 33%;
    margin-right: 15px !important;
  }
  
  .EditHotel-LocationARInput {
    width: 33%;
  }
  
  .EditHotel-DescriptionARInput {
    width: 100%;
  }
  
  .EditHotel-DescriptionARInput div .MuiInputBase-input {
    height: unset !important;
  }
  
  .EditHotel-HotelCoordinatesContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #ddd !important;
    margin: 0;
    padding: 10px;
    position: relative;
    border-radius: 4px;
    background-color: #fbfbfb;
    padding-left: 10px !important;
    padding-top: 35px;
  }
  
  .EditHotel-HotelHarmCoordinatesContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #ddd !important;
    margin: 0;
    padding: 10px;
    position: relative;
    border-radius: 4px;
    background-color: #fbfbfb;
    padding-left: 10px !important;
    padding-top: 35px;
    margin-top: 55px !important;
  }

  .EditHotel-HotelCoordinatesHead {
    margin-bottom: 0px;
    width: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px 5px 5px 10px;
    background-color: #ffffff;
    position: absolute;
    top: -15%;
    display: flex;
  }
  
  .EditHotel-HotelCoordinatesHeadTitle {
    color: #393737 !important;
    font-family: "Cairo", sans-serif !important;
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  
  .EditHotel-MapContainer {
    display: flex;
    width: 50%;
  }
  
  .EditHotel-HotelLngInput div .MuiInputBase-input {
    background-color: #fff !important;
  }
  
  .EditHotel-HotelLatInput div .MuiInputBase-input {
    background-color: #fff !important;
  }
  
  .EditHotel-HotelLngInput {
    width: 100%;
    margin-top: 20px !important;
  }
  
  .EditHotel-HotelLatInput {
    width: 100%;
  }
  
  .EditHotel-HotelCoordinatesMarker {
    color: #cf2528 !important;
  }

  .EditHotel-HotelHaramCoordinatesMarker {
    color: #00b437 !important;
  }
  
  .EditHotel-PricingAmountInput {
    width: 25%;
    margin-right: 15px !important;
  }
  
  .EditHotel-RenewalCountInput {
    width: 25%;
    margin-right: 15px !important;
  }
  
  .EditHotel-FloorsCountInput {
    width: 25%;
    margin-right: 15px !important;
  }
  
  .EditHotel-DeluxeRoomsCountInput {
    width: 25%;
  }
  
  .EditHotel-RegularRoomsCountInput {
    width: 25%;
    margin-right: 15px !important;
  }
  
  .EditHotel-RegularSuitesCountInput {
    width: 25%;
    margin-right: 15px !important;
  }
  
  .EditHotel-DeluxeSuitesCountInput {
    width: 25%;
    margin-right: 15px !important;
  }
  
  .EditHotel-ParkingCountInput {
    width: 25%;
  }
  
  .EditHotel-FacilityOwnerInput {
    width: 33%;
    margin-right: 15px !important;
  }
  
  .EditHotel-OperatorNameInput {
    width: 33%;
    margin-right: 15px !important;
  }
  
  .EditHotel-OperatorGenderInput {
    width: 34% !important;
  }
  
  .EditHotel-LicensingInvestorInput {
    width: 50%;
    margin-right: 15px !important;
  }
  
  .EditHotel-FacilityLicensingStartDateInput {
    width: 50%;
  }
  
  .EditHotel-FeaturesContainer {
    display: grid !important;
    grid-template-columns: auto auto auto auto;
  }
  
  .EditHotel-FeaturesContent-Checkbox .MuiTypography-body1 {
    color: #3F4F61 !important;
    font-family: "Cairo", sans-serif !important;
    font-size: 13px !important;
    font-weight: 600 !important;
  }
  
  .EditHotel-UploadPhoto {
    font-family: "Cairo", sans-serif !important;
      font-size: 15px !important;
      color: #fff !important;
      font-weight: bold !important;
      background-color: #0acb33 !important;
      padding: 12px 12px !important;
      border-radius: 6px !important;
      border: solid 2px #eee !important;
      cursor: pointer;
  }
  
  .EditHotel-RemoveAllPhoto {
    font-family: "Cairo", sans-serif !important;
      font-size: 15px !important;
      color: #fff !important;
      font-weight: bold !important;
      background-color: #cf2528 !important;
      padding: 12px 12px !important;
      border-radius: 6px !important;
      border: solid 2px #eee !important;
      cursor: pointer;
  }
  
  .EditHotel-UpdatePhoto {
    font-family: "Cairo", sans-serif !important;
      font-size: 13px !important;
      color: #fff !important;
      font-weight: bold !important;
      background-color: #0acb33 !important;
      padding: 6px 6px !important;
      border-radius: 3px !important;
      border: solid 1px #eee !important;
      cursor: pointer;
  }
  
  .EditHotel-RemovePhoto {
    font-family: "Cairo", sans-serif !important;
      font-size: 13px !important;
      color: #fff !important;
      font-weight: bold !important;
      background-color: #cf2528 !important;
      padding: 6px 6px !important;
      border-radius: 3px !important;
      border: solid 1px #eee !important;
      cursor: pointer;
  }
  
  .EditHotel-Images {
    display: grid !important;
    grid-template-columns: auto auto auto auto;
  }
  
  .EditHotel-HotelCoordinatesColumn {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  
  @media only screen and (min-width: 0px) and (max-width: 575px) {
    .EditHotel-Container {
      padding: 0px 35px 0px 35px;
    }
  }
  
  @media only screen and (min-width: 576px) and (max-width: 799px) {
    .EditHotel-Container {
      padding: 0px 35px 0px 35px;
    }
  }
  
  @media only screen and (min-width: 800px) and (max-width: 1023px) {
    .EditHotel-Container {
      padding: 0px 35px 0px 35px;
    }
  }
  
  @media only screen and (min-width: 1024px) and (max-width: 1199px) {
    .EditHotel-Container {
      padding: 0px 70px 0px 70px;
    }
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .EditHotel-Container {
      padding: 0px 70px 0px 70px;
    }
  }
  