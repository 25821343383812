.DeleteUserContainer {
    display: flex;
    flex-direction: column;
  }
  
  .DeleteUser-HeadContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  
  .DeleteUser-HeadTitle {
    font-family: "Cairo", sans-serif !important;
    font-size: 18px !important;
    color: #393737;
    font-weight: 600 !important;
  }
  
  .DeleteUserLine {
    height: 1px;
    opacity: 0.2;
    background-color: #393737;
  }
  
  .DeleteUserFormContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .DeleteUserFormContent {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
  }
  
  .DeleteUserInput {
      margin-bottom: 15px !important;
  }
  
  .DeleteUserInput .MuiFormLabel-root {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: rgba(57, 55, 55, 0.4) !important;
    font-weight: 600 !important;
  }
  
  .DeleteUserInput div .MuiOutlinedInput-notchedOutline {
    border-radius: 6px !important;
    border: solid 2px #eee !important;
  }
  
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #3f7cdf !important;
  }
  
  .DeleteUserInput .MuiFormLabel-root.Mui-focused {
    color: #3f7cdf !important;
  }
  
  .DeleteUserInput div .MuiInputBase-input {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: rgba(57, 55, 55, 0.4) !important;
    font-weight: 600 !important;
  }
  
  .DeleteUserInput div .MuiInputBase-input:focus {
    color: #393737 !important;
  }
  
  .DeleteUserInputRequired {
    color: #cf2528 !important;
    font-family: "Cairo", sans-serif !important;
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  
  .MuiCheckbox-root {
    color: #eee !important;
  }
  
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #3f7cdf !important;
  }
  
  .DeleteUserFormFooter {
    display: flex;
    margin-top: 25px;
  }
  
  .DeleteUserCancelButton {
    font-family: "Cairo", sans-serif !important;
    font-size: 15px !important;
    color: #fff !important;
    font-weight: bold !important;
    background-color: #3f7cdf !important;
    padding: 12px 0px !important;
    border-radius: 6px !important;
    border: solid 2px #eee !important;
    width: 100% !important;
  }

  .DeleteUserDeleteUserButton {
    font-family: "Cairo", sans-serif !important;
    font-size: 15px !important;
    color: #fff !important;
    font-weight: bold !important;
    background-color: #cf2528 !important;
    padding: 12px 0px !important;
    border-radius: 6px !important;
    border: solid 2px #eee !important;
    width: 100% !important;
  }
  
  .DeleteUserFormInputError .MuiFormHelperText-root.Mui-error {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: #cf2528 !important;
    font-weight: 600 !important;
  }
  
  .DeleteUserFormInputError .MuiFormHelperText-root {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: #cf2528 !important;
    font-weight: 600 !important;
  }
  
  .DeleteUser-CheckboxContainer {
      display: flex;
      flex-direction: column;
      border: solid 2px #eee !important;
      border-radius: 6px;
      padding: 2px 15px;
      margin-bottom: 15px;
  }
  
  .DeleteUser-CheckboxTitle {
      font-family: "Cairo", sans-serif !important;
      font-size: 14px !important;
      color: #b0afaf !important;
      font-weight: 600 !important;
  }
  
  .DeleteUser-Checkbox {
      display: flex !important;
      flex-direction: row !important;
  }

  .DeleteUser-SubHeadTitle {
    font-family: "Cairo", sans-serif !important;
    font-size: 12px !important;
    color: #cf2528 !important;
    font-weight: 600 !important;
  }

  .DeleteUser-SubContentTitle {
    font-family: "Cairo", sans-serif !important;
    font-size: 12px !important;
    color: #cf2528 !important;
    font-weight: 600 !important;
  }

  .DeleteUser-ContentTitle {
    font-family: "Cairo", sans-serif !important;
    font-size: 16px !important;
    color: #393737;
    font-weight: 600 !important;
  }